const bannerTxt = ` ______ _   _   ___   _   _ _____________   ________ _     _____ _____
|___  /| | | | / _ \\ | \\ | |  __ \\  _  \\ \\ / /| ___ \\ |   |  _  |  __ \\
   / / | |_| |/ /_\\ \\|  \\| | |  \\/ | | |\\ V / | |_/ / |   | | | | |  \\/
  / /  |  _  ||  _  || . \` | | __| | | |/   \\ | ___ \\ |   | | | | | __
./ /___| | | || | | || |\\  | |_\\ \\ |/ // /^\\ \\| |_/ / |___\\ \\_/ / |_\\ \\
\\_____/\\_| |_/\\_| |_/\\_| \\_/\\____/___/ \\/   \\/\\____/\\_____/\\___/ \\____/

`;

export function bannerPrint() {
    console.log(bannerTxt);
}