import { render, staticRenderFns } from "./ArticleListTab.vue?vue&type=template&id=3d2b9246&scoped=true"
import script from "./ArticleListTab.vue?vue&type=script&lang=js"
export * from "./ArticleListTab.vue?vue&type=script&lang=js"
import style0 from "./ArticleListTab.vue?vue&type=style&index=0&id=3d2b9246&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2b9246",
  null
  
)

export default component.exports