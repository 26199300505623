var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('skeleton',{attrs:{"type":"custom","options":{ width: '100%', height: '100%' },"childrenOption":[
              {
                type: 'card',
                rules: 'a, d, g',
                active: true,
                options: { width: '100%', height: '80%' },
              },
              { type: 'listcom', rules: 'b, c, e, f, h, i', active: true },
            ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }