<template>
<div class="nav-content">

</div>
</template>

<script>
export default {
  name: "NewNavBar"
}
</script>

<style scoped>

</style>