import articleApi from './article'
import categoryApi from './category'
import friendshipLinkApi from './friendship-link'
import topicApi from './topic'
import updateLogApi from './updatelog'
import searchApi from './search'
import oauthApi from './oauth'
import commonApi from './common'
import contentApi from './content';
import blogLikeApi from './blog-like'
import commentApi from './comment'
import homepage from './homepage';

export default {
  articleApi,
  categoryApi,
  friendshipLinkApi,
  topicApi,
  updateLogApi,
  searchApi,
  oauthApi,
  commonApi,
  contentApi,
  blogLikeApi,
  commentApi,
  homepage
}
